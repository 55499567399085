<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.billicomm.cn/official/website/assets/product/bj3.png" style="width: 100%; height: 100%" />
    </div>
    <div class="centertop">
      <label v-for="(item, index) in dataList" :key="index" :class="selectedIndex == index ? 'active' : ''"
        @click.stop="selectBox(item, index)">
        {{ item.value }}
      </label>
    </div>
    <div class="product" v-if="selectedIndex == 0">
      <div class="topimg" v-for="(item, index) in imgtop" :key="index" @click="imgtap(index)">
        <img style="width:100%;height:100%;" :src="item" alt="">
      </div>
    </div>
    <div class="videoions" v-if="selectedIndex == 0">
      <div class="title">{{ titlist[dex].title }}</div>
      <div>{{ titlist[dex].txt }}</div>
    </div>
    <div v-else class="videoions">
      <div class="title">{{ flagone[selectedIndex].title }}</div>
      <div>{{ flagone[selectedIndex].txt }}</div>
    </div>
    <!-- 行业现状 -->
    <Industry :flag="selectedIndex" :dex="dex"></Industry>
    <!-- 一站式解决方案 -->
    <Solution :flag="selectedIndex" :dex="dex"></Solution>
    <!-- 方案价值??-->
    <Project :flag="selectedIndex" :dex="dex"></Project>
    <!-- 方案场景-->
    <Scene :flag="selectedIndex" :dex="dex"></Scene>
    <!-- 平台介绍?-->
    <Introduction :flag="selectedIndex" :dex="dex"></Introduction>
    <!-- 智能硬件?-->
    <Hardware :flag="selectedIndex"></Hardware>
    <!-- 应用场景-->
    <Casesse :flag="selectedIndex" v-if="dex == 0"></Casesse>

    <div class="lastpart">
      <div style="padding:50px 0 30px 0;font-size: 34px;font-weight: 400;color: #291C37;">佰鹿科技：物联网整体解决方案提供商</div>
      <div style="font-size: 24px;font-weight: 400;color: #291C37;">想获得专属解决方案？</div>
      <button @click="goclick()">去试试 >></button>
    </div>
  </div>
</template>

<script>
import Industry from "../../components/Industry";
import Solution from "../../components/Solution";
import Project from "../../components/Project";
import Scene from "../../components/Scene";
import Introduction from "../../components/Introduction";
import Hardware from "../../components/Hardware";
import Casesse from "../../components/Casesse.vue";
export default {
  name: "product",
  components: {
    Industry,
    Solution,
    Project,
    Scene,
    Introduction,
    Casesse,
    Hardware,

  },
  data() {
    return {
      selectedIndex: 0,
      dex: 0,
      imgtop: ['https://advertisement.billicomm.cn/official/website/assets/product/mc1.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car2.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car3.png'],
      dataList: [
        { key: '1', value: "数字交通" },
        { key: '2', value: "数字社区" },
        { key: '3', value: "数字消防" },
        { key: '4', value: "数字农业" },
        { key: '5', value: "资产管理" },
      ],
      titlist: [{
        title: '电动自行车综合管理解决方案', txt: '构建标准统一、功能完善、集中统一的服务平台，解决电动自行车管理难题',

      },
      {
        title: '智慧停车综合解决方案', txt: '构建统一、完善的停车管理云平台，实现对城市车辆信息化、智能化的全方位优化管理与控制。',

      },
      {
        title: '公车管理解决方案', txt: '充分利用物联网与车联网技术，精准掌控各区域、各部门公务车辆运营状况，实现车辆的高效高质管理。',
      },],
      flagone: [
        {
          title: '电动自行车综合管理解决方案', txt: '构建标准统一、功能完善、集中统一的服务平台，解决电动自行车管理难题',
          //智能硬件
          img: 'baz.png'
        },
        {
          title: '社区综合治理解决方案', txt: '通过“物联网”+“互联网”的整合与连接，实现社区综合管理集中化、统筹化、智能化。',

          //智能硬件
          img: 'baz1.png'
        },
        {
          title: '微众消防智慧管理方案', txt: '多种终端设备信息采集，云平台智能化数据管理，实现火灾防控自动化，救援指挥智能化',
          //智能硬件
          img: 'baz.png'
        },
        {
          title: '肉牛养殖业解决方案', txt: '从根源出发，解决农户养殖存在的多方面风险问题，使农户、金融机构、担保机构之间互利互惠。',
          //智能硬件
          img: 'baz.png'
        },
        {
          title: '固定资产跟踪管理方案', txt: '构建统一资产管理云平台，完善资产入库、登记、借用、归还、丢失、损耗的一系列管理流程，使企业固定资产管理可视化、透明化。',

          //智能硬件
          img: 'baz.png'
        },
      ],
      height: document.documentElement.clientWidth / 4.47,

      imgs: [
        { url: "b1.png", tit: '道路交通治理平台', txt: '实时监控、车辆管理、智能预警、违章管理、布控管理、大数据监控' },
        { url: "b2.png", tit: '充电安全综合管理平台', txt: '数据分析、物视联动、充电调度、入户管控、潜在高危车主筛选' },
        { url: "b3.png", tit: '共享车辆监管平台', txt: '车辆备案、车辆监控、站点监控、网格违规行为统计监管' }],
    };
  },
  methods: {
    selectBox(item, index) {
      this.selectedIndex = index;
      this.dex = 0;
    },
    goclick() {
      // window.open('https://moguxiaobing.tmall.com/', '_blank');
      this.$router.push({ name: 'service' })
    },
    imgtap(dex) {
      this.dex = dex;
      if (dex == 0) {
        this.imgtop = ['https://advertisement.billicomm.cn/official/website/assets/product/mc1.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car2.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car3.png']
      } else if (dex == 1) {
        this.imgtop = ['https://advertisement.billicomm.cn/official/website/assets/product/car1.png', 'https://advertisement.billicomm.cn/official/website/assets/product/mc2.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car3.png']
      } else {
        this.imgtop = ['https://advertisement.billicomm.cn/official/website/assets/product/car1.png', 'https://advertisement.billicomm.cn/official/website/assets/product/car2.png', 'https://advertisement.billicomm.cn/official/website/assets/product/mc3.png']
      }

    }
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}


.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}




.lastpart {
  text-align: center;
  width: 100%;
  background-color: #E0F0FD;
  line-height: 30px;
}

.lastpart button {
  width: 313px;
  height: 56px;
  border-radius: 30px;
  background-color: #167BCE;
  color: #FFFFFF;
  border: none;
  font-size: 24px;
  margin: 50px 0;
}



.title {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 20px 0;
}






.product {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}





.topimg {
  width: 382px;
  height: 174px;
}

.centertop {
  text-align: center;
  margin: 60px 0;
  font-size: 24px;
}

.centertop label {
  border-bottom: 1px solid #E6E6E6;
  width: 260px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  color: #000000;
}




.centertop .active {
  color: #2681D5;
  border-bottom: 2px solid #006FD4;
}

.centertop label:hover {
  color: #2681D5;
}
</style>