<template>
  <div class="backimag" v-if="flag == 0">
    <div class="videoions" style="padding-top:5px">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borders"></label>
        应用案例
        <label class="borders"></label>
      </div>
      <div class="videoa">
        <div class="apart"><label class="aborder"></label>充电安全管理<label class="aborder"></label></div>
        <div class="apart"><label class="aborder"></label>共享电单车管理<label class="aborder"></label></div>
      </div>
      <div class="videoall">
        <div class="box">
          <img style="width:58%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/s1.png" alt="">
          <img style="width:38%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/s2.png" alt="">
        </div>
        <div class="box">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/s3.png" alt="">
        </div>
      </div>
      <div class="videoa">
        <div class="apart">
          <label class="aborder"></label>海盐道路交通治理<label class="aborder"></label>
        </div>
        <div class="apart"><label class="aborder"></label>开封电单车防盗追踪治理<label class="aborder"></label></div>
      </div>
      <div class="videoall">
        <div class="box">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/s4.png" alt="">
        </div>
        <div class="boxs">
          <img style="width:50%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/s5.png" alt="">
          <img style="width:10%;height:100%;margin-left:10px" src="https://advertisement.billicomm.cn/official/website/assets/product/s6.png" alt="">
          <div class="text">
            <div>
              完成31723辆车辆备案
            </div>
            <div>
              完成31723辆车辆备案。
              完成800个停车站点围栏施画，
              30个网格建设。
            </div>
            <div>
              收集762W条订单与轨迹数据，
              监控到573W条违规数据。
            </div>
            <div>
              各家车企日均违规数量下降42%，
              违规处理率99%，违规及时处理
              率目前达到40%~68%之间。

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="backimag" v-else-if="flag == 1">
    <div class="videoions" style="padding-top:5px">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borders"></label>
        应用案例
        <label class="borders"></label>
      </div>
      <div class="videoall">
        <div class="boxother">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/all1.png" alt="">
        </div>
        <div class="boxother">
          <div class="text">
            <div style="font-size: 24px;font-weight: 500;margin:30px 20px;">
              改造项目
            </div>
            <div style="font-size: 18px;font-weight: 400;line-height: 40px;padding:0 20px;">
              实现车辆集中停放、集中充电、集中管理，有效解决城中村非机动车停放不规范、公共充电资源不足、集中充电场所缺乏管理等问题，大大提升了城中村内消防安全系数，是深圳市非机动车消防安全综合治理专项行动典型示范项目。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="backimag" v-else-if="flag == 2">
    <div class="videoions" style="padding-top:5px">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borders"></label>
        应用案例
        <label class="borders"></label>
      </div>
      <div class="videoall">
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/g1.png" alt="">
          <div>河南济源——九小场所防火案例</div>
          <!-- <div>针对各街道、社区中的“九小”场所，安装物联网消防设备，搭建消防大数据平台，实现片区消防动态的实时感知，实现火情提前预防。</div> -->
        </div>
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/g3.png" alt="">
          <div>山东省聊城市人民医院——医院防火案例</div>
          <!-- <div>医院人流大，火灾情况复杂，属于高火灾风险场所。系统兼容医院原有基础消防设施，实现对医院消防情况整体把控，在节省人力的同时，提高了医院消防安全等级。</div> -->
        </div>
      </div>
      <div class="videoall">
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/g3.png" alt="">
          <div>园区防火案例——佰鹿电子世界</div>
          <!-- <div>险情后果不堪设想。佰鹿帮助园区完善消防系统设计，提供物联网消防设备，建立起动态化、立体化、精细化的消防安全监管体系。</div> -->
        </div>
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/g4.png" alt="">
          <div>家庭防火案例</div>
          <!-- <div>物联网消防设备实时监测家中消防状况，一旦出现异常情况，立即报警，有效预防家庭火灾。</div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="backimagall" v-else-if="flag == 3">
    <div class="videoions" style="padding-top:5px;height:950px">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borders"></label>
        应用场景
        <label class="borders"></label>
      </div>
      <div class="videoall">
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/v1.png" alt="">
          <div class="fonts">肉牛养殖产业</div>
        </div>
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/v2.png" alt="">
          <div class="fonts">金融机构活体资产管理</div>
        </div>
      </div>
      <div class="videoall">
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/v3.png" alt="">
          <div class="fonts">担保机构活体资产监管</div>
        </div>
        <div class="boxsel" style="border:none">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/v4.png" alt="">
          <div class="fonts">保险行业牲畜养殖类业务风险管理</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Lastpart",
  // 接受父组件的值
  props: {
    flag: Number,
    required: true
  },
  data() {
    return {
      flagone: [
        {
          img: 'baz.png'
        },
        {
          img: 'baz1.png'
        },
        {
          img: 'baz.png', imgs: 'baz1.png', txt: ''
        },
        {
          img: 'baz.png', imgs: 'baz1.png', txt: ''
        },
        {
          img: 'baz.png'
        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoions {
  width: 1200px;
  
  text-align: center;
  margin: 0 auto;

}

.messages {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.backimag {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/bga.png');
  background-position: center center;
  background-size: 100% 100%;
}

.backimagall {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/ceb.png');
  background-position: center center;
  background-size: 100% 100%;
  position: relative;
  top: 10px;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.aborder {
  border-bottom: 1px solid #FFFFFF;
  width: 100px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.fonts {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  padding: 20px 0;

}

.videoa {
  width: 100%;
  display: flex;
}

.apart {
  font-size: 18px;
  margin-top: 30px;
  font-weight: 400;
  width: 50%;
  text-align: center;
  color: #FFFFFF;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}

.videoall {
  width: 100%;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
}




.boxsel {
  width: 48%;
  height: 280px;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
}

.box {
  border: 1px solid #FFFFFF;
  border-top: none;
  width: 48%;
  height: 280px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.boxs {
  border: 1px solid #FFFFFF;
  border-top: none;
  width: 48%;
  height: 280px;
  margin-bottom: 50px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

.boxother {
  width: 600px;
  height: 450px;
  margin-bottom: 50px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}
</style>