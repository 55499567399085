<template>
  <!-- 解决方案 -->
  <div class="backimg" v-if="flag == 2 || flag == 3">
    <div class="videoions">
      <div class="message" style="color: #FFFFFF;">
        <label class="borders" style="border-bottom: 1px solid #FFFFFF"></label>
        {{ flagone[flag].title }}
        <label class="borders" style="border-bottom: 1px solid #FFFFFF"></label>
        <div style="font-size:18px;margin-top:30px;font-weight: 400;padding:0 150px;line-height: 36px;">
          {{ flagone[flag].text }}</div>
      </div>
      <div>
        <img style=" width:1000px;" :src="flagone[flag].textimg" alt="">
      </div>
    </div>
  </div>
  <div class="videoions" v-else>
    <div class="message">
      <label class="borders"></label>
      一站式解决方案
      <label class="borders"></label>
    </div>
    <div class="product">
      <div :style="dex == 0 ?'width:60%; height: 368px;':'width:55%; height: 368px;'" v-if="flag == 0">
        <img style=" width:100%;height:100%;" :src="tablist[dex].textimg" alt="">
      </div>
      <div style='width:60%; height: 428px;' v-if="flag == 1">
        <img style=" width:100%;height:100%;" :src="flagone[flag].textimg" alt="">
      </div>
      <div style='width:50%;  height: 357px;' v-if="flag == 4">
        <img style=" width:100%;height:100%;" :src="flagone[flag].textimg" alt="">
      </div>
      <div class="righttxts">
        <div class="txts">
          {{ flag == 0 ? tablist[dex].text : flagone[flag].text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lastpart",
  // 接受父组件的值
  props: {
    flag: Number,
    dex: Number,
    required: true
  },
  data() {
    return {
      tablist: [
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz.png',
          text: '依托运营商物联网开放平台及NB-IoT网络，构建标准统一、功能完善、集中统一的服务平台。平台通过实时采集车辆地理位置、用户信息、第三方数据等各类信息，实现交通管控、防盗追踪等功能，有效解决电动车管理带来的交通、治安问题。',

        },
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz0.png',
          text: ' 通过技术手段构建统一、完善的停车管理云平台，对城市车辆管理提供信息化、智能化的全方位优化管理与控制，降低人工成本，提升管理者的工作效率及车位周转率，彻底解决路边停车难、停车场管理难等痛点。',

        },
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz3.png',
          text: '充分利用物联网与车联网技术手段，实现不同区域、不同部门公务车日常运营情况的精确掌控，为公务车监督、管理、服务提供有效支撑，实现对公务车运行的流程化、动态化、网格化、透明化、人性化管理。',

        },
      ],
      flagone: [
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz.png',
          text: 'IoT网络，构建标准统一、功能完善、集中统一的服务平台。平台通过实时采集车辆地理位置、用户信息、第三方数据等各类信息，实现交通管控、防盗追踪等功能，有效解决电动车管理带来的交通、治安问题。',

        },
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz1.png',
          text: ' 通过“物联网+互联网” 的整合和连接，以“数据驱动，主动服务”为特征，以“保民生、促和谐”为基本理念，集电动自行车管理、消防管理、社区安防管理、高空抛物监测、垃圾分类管理、社区大数据六大版块于一体，可实现社区运行状态、可知、可控、可预测及管理集中化、统筹化、智能化，从而推进社区治理体系和治理能力的现代化，进一步提升社区居民的获得感、幸福感和安全感。',

        },
        {
          title: '一站式解决方案',
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/q2.png',
          text: ' 佰鹿科技通过云平台及多种终端设备实时实现消防信息采集、传递和处理，为防火监督管理和灭火救援提供信息支撑，提供消防应用场景下的智慧化解决方案。',
        },
        {
          title: '一体化解决方案',
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/q1.png',
          text: ' 运用科技手段将非标准化的不可确权抵押的肉牛活体资产转变为标准化的可确权抵押的金融性资产，由保险公司出具养殖保险、担保公司承担保证责任担保，在对肉牛活体进行工商确权登记后实现抵押，合作银行发放贷款。',

        },
        {
          textimg: 'https://advertisement.billicomm.cn/official/website/assets/indust/lastz0.png',
          text: 'IoT网络，构建标准统一、功能完善、集中统一的服务平台。平台通过实时采集车辆地理位置、用户信息、第三方数据等各类信息，实现交通管控、防盗追踪等功能，有效解决电动车管理带来的交通、治安问题。',

        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  padding: 60px 0;
}

.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}







.videoall {
  width: 100%;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #747575;
}

.righttxts {
  text-align: left;
  padding: 50px;
  box-sizing: border-box;
  width: 40%;
  color: #FFFFFF;
  background: #175085;
}


.txts {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 36px
}

.product {
  display: flex;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  justify-content: space-between;
  margin-bottom: 106px;
  padding: 0 10px;
}

.backimg {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/backp.png');
  background-position: center center;
  background-size: 100% 100%;
  padding-bottom: 150px;
}
</style>