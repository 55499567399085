<template>
  <div class="downLoad">
    <div class="download_box">
     <img src="" alt="" />
    </div>
    <div class="download_box">
     <img src="" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang='scss' scoped>
  .download_box{
    width: 1200px;
    height: 700px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 15px;
    img{
      width: 100%;
      height:100%;
    }
  }
  .download_ewm{
    width: 186px;
    font-size: 12px;
    text-align: center;
    margin-top: 9px;}
</style>
