<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.billicomm.cn/official/website/assets/index/banner4.png"
        style="width: 100%; height: 100%" />
    </div>
    <div class="video">
      <div class="message">
        <label class="border"></label>
        公司简介
        <label class="border"></label>
        <div style="font-size: 18px;font-weight: 300;margin-top:20px;">Company Profile</div>
      </div>
      <div class="product_top">
        <div style="width: 582px; height: 264px; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="../../assets/index/company.png" alt="">
        </div>
        <div class="righttxt">
          <div class="txts" style="margin-bottom:20px">
            杭州佰鹿信息科技有限公司创始于2020年10月，注册资本10,000万元，法人代表杨斌先生，公司为金卡智能集团（股票代码：300349）的控股子公司。

            佰鹿科技总部位于美丽的西子湖畔—杭州，目前公司在杭州和西安分别设有运营管理中心和产品研发中心。

            佰鹿的目标是聚焦物联网行业研发、应用和生产制造，汇聚物联网行业高端人才，打造拼搏创新的团队，持续为客户提供先进的物联网产品和综合解决方案，实现与客户共赢。
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="video">
      <div class="message">
        <label class="border"></label>
        新闻中心
        <label class="border"></label>
      </div>
      <div class="listcenter">
        <div class="bordertxt">
          <div class="tittop">
            <img style="width: 15px; height: 100%;" src="https://advertisement.billicomm.cn/official/website/assets/aboutus/right.png" alt="">
            公司动态
            <img style="width: 15px; height: 100%;" src="https://advertisement.billicomm.cn/official/website/assets/aboutus/left.png" alt="">
          </div>
          <div class="product_tops" v-for='(item,index) in imglist' :key='index' @click='detail(item)'>
            <div style="width: 284px; height: 129px; background: #fcfcfc;position:relative;bottom:15px;">\
              <img style="width:100%;height:100%;" :src="item.thumbnail" alt="">
            </div>
            <div class="txtilt">
              <div class="timetil">{{ item.comment_text }}</div>
              <div class="time">{{ new Date(+new Date(new
                  Date(item.create_time).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g, '').replace(/\.[\d]{3}Z/, '')}}</div>
            </div>
          </div>
          <div style="text-align:left;margin:50px 20px;cursor:pointer;" @click='carouselClick(1)'>查看更多>></div>
        </div>
        <div class="bordertxt">
          <div class="tittop">
            <img style="width: 15px; height: 100%;" src="https://advertisement.billicomm.cn/official/website/assets/aboutus/right.png" alt="">
            行业动态
            <img style="width: 15px; height: 100%;" src="https://advertisement.billicomm.cn/official/website/assets/aboutus/left.png" alt="">
          </div>
          <div class="product_tops" v-for='(item,index) in imglists' :key='index' @click='detail(item)'>
            <div style="width: 284px; height: 129px; background: #fcfcfc">
              <img style="width:100%;height:100%;" :src="item.thumbnail" alt="">
            </div>
            <div class="txtilt">
              <div class="timetil">{{ item.comment_text }}</div>
              <div class="time">{{ new Date(+new Date(new
                  Date(item.create_time).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g, '').replace(/\.[\d]{3}Z/, '')}}</div>
            </div>
          </div>
          <div style="text-align:left;margin:50px 20px;cursor:pointer;" @click='carouselClick(2)'>查看更多>></div>
        </div>
      </div>
    </div> -->


    <div class="video">
      <div class="message">
        <label class="border"></label>
        企业文化
        <label class="border"></label>
        <div style="font-size: 18px;font-weight: 300;margin-top:20px;">Corporate Culture</div>
      </div>
      <div class="product_top">
        <div class="bottomimg" style="margin-right:35px;">
          <img style="width:100%;height:100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/bf.png" alt="">
          <div class="img">
            <img style="width:100px;height:100px;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/1.png" alt="">
            <div style="font-size: 26px;margin-top:30px;font-family: Source Han Sans CN;font-weight: 500;color: #000000;">
              诚信为本</div>
          </div>
        </div>
        <div class="bottomimg" style="margin-right:35px;">
          <img style="width:100%;height:100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/bf.png" alt="">
          <div class="img">
            <img style="width:100px;height:100px;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/2.png" alt="">
            <div style="font-size: 26px;margin-top:30px;font-family: Source Han Sans CN;font-weight: 500;color: #000000;">
              服务至上</div>
          </div>
        </div>
        <div class="bottomimg" style="margin-right:35px;">
          <img style="width:100%;height:100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/bf.png" alt="">
          <div class="img">
            <img style="width:100px;height:100px;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/3.png" alt="">
            <div style="font-size: 26px;margin-top:30px;font-family: Source Han Sans CN;font-weight: 500;color: #000000;">
              品质第一</div>
          </div>
        </div>
        <div class="bottomimg">
          <img style="width:100%;height:100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/bf.png" alt="">
          <div class="img">
            <img style="width:100px;height:100px;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/4.png" alt="">
            <div style="font-size: 26px;margin-top:30px;font-family: Source Han Sans CN;font-weight: 500;color: #000000;">
              合作共赢</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      height: document.documentElement.clientWidth / 4.47,
      img: [
        { url: "icon1.png", tit: '发明专利' },
        { url: "icon2.png", tit: '实用新型专利' },
        { url: "icon3.png", tit: '外观设计专利' },
        { url: "icon4.png", tit: '计算机软件著作权' }],
      imglist: [],
      imglists: []
    };
  },
  methods: {
    carouselClick(val) {
      this.$router.push('/newsReport')
    },
    detail(val) {
      console.log(val)
      localStorage.setItem('newsId', val.id)
      this.$router.push('/newsDetail')
    },
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
    this.params = {
      page: 0,
      size: 6
    },
      this.$api.eggDemo(this.params)
        .then(res => {
          this.imglist = res.data.data.array.slice(0, 3)
          this.imglists = res.data.data.array.slice(3, 6)
        }).catch(err => {
          console.log(err)
        })
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}

.backimg {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/backt.png');
  width: 100%;
  height: 1050px;
  background-position: center center;
}

.video {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 120px;
}


.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.messages {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  margin: 100px 0;
}

.border {
  border-bottom: 1px solid #C9C9C9;
  width: 320px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}


.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 320px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.listcenter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #FFFFFF;
}

.bordertxt {
  border: 1px solid #929292;
  width: 49%;
}

.product_top {
  display: flex;
  height: 283px;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
  color: #FFFFFF;
}

.product_tops {
  display: flex;
  margin: 20px;
}

.tittop {
  font-size: 24px;
  margin: 50px 0;
}

.txtilt {
  text-align: left;
}

.txtilt .time {
  margin: 20px;
}

.timetil {
  width: 300px;
  height: 90px;
  overflow: hidden;
  line-height: 30px;
  margin-left: 20px;
}

.righttxt {
  width: 595px;
  height: 100%;
  text-align: left;
  padding: 10px 60px 0 60px;
  box-sizing: border-box;
}

.rigbottom {

  width: 600px;
  height: 100%;
  text-align: left;
  padding: 0 60px;
  box-sizing: border-box;
}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #747575;
  line-height: 30px
}


.bottomimg {
  width: 268px;
  height: 375px;
  position: relative;
}

.bottomimg>.img {
  height: 200px;
  width: 268px;
  position: absolute;
  top: 60px;
}</style>