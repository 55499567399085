<template>
  <!-- 只能硬件 -->
  <div class="videoions" v-if="flag == 2">
    <div class="messages">
      <label class="borders"></label>
      智能硬件
      <label class="borders"></label>
    </div>
    <div class="list">
      <div class="box">
        <div class="boximg">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/x1.png" alt="">
        </div>
        <div class="boxtxt">
          <div style="margin:10px 0;color: #121212;font-size: 20px;">可燃气体探测器</div>
          <div style="color:#747575;font-size: 16px;">·NB-IoT通信，覆盖范围广，传输稳定；
            ·APP实时读取水压数据并上传至云平台；
            ·设备内置到消防栓中，安全便捷；
            ·超长时间待机（每天上报一次，可待机3年）</div>
        </div>
      </div>
      <div class="box">
        <div class="boximg">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/x2.png" alt="">
        </div>
        <div class="boxtxt">
          <div style="margin:10px 0;color: #121212;font-size: 20px;">可燃气体报警器</div>
          <div style="color:#747575;font-size: 16px;">·NB-IoT通信，覆盖范围广，传输稳定；
            ·测控多种可燃气体浓度、成分、收集并传递信息；
            ·低功耗，气体浓度异常声光报警</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="box">
        <div class="boximg">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/x3.png" alt="">
        </div>
        <div class="boxtxt">
          <div style="margin:10px 0;color: #121212;font-size: 20px;">烟雾报警器</div>
          <div style="color:#747575;font-size: 16px;">·NB-IoT通信，覆盖范围广，传输稳定；
            ·烟雾数据上传云平台；实时监测烟雾浓度；
            ·设备可吸顶快速安装；
            ·低功耗模块，正常使用可达3年</div>
        </div>
      </div>
      <div class="box">
        <div class="boximg">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/x4.png" alt="">
        </div>
        <div class="boxtxt">
          <div style="margin:10px 0;color: #121212;font-size: 20px;">CO探测器</div>
          <div style="color:#747575;font-size: 16px;">·NB-IoT通信，覆盖范围广，传输稳定；
            ·测控CO气体浓度，收集并传递信息；
            ·CO浓度异常声光报警</div>
        </div>
      </div>
    </div>
    <div class="titles">了解更多产品细节 >></div>
  </div>
  <div class="videoions" v-else-if="flag == 3">
    <div class="messages">
      <label class="borders"></label>
      智能硬件
      <label class="borders"></label>
    </div>
    <div style="display: flex;">
      <div class="boxs">
        <div class="boximgs">
          <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/product/x5.png" alt="">
        </div>
        <div class="boxtxts">
          <div style="margin:10px 0;color: #121212;font-size: 20px;">牛更旺</div>
          <div style="color:#747575;font-size: 16px;">超大容量，超长待机；
            体积小巧，重量超轻；
            政策支持 贷款必备；
            抗冻防寒 不易损坏。</div>
        </div>
      </div>
    </div>
    <div class="titles">了解更多产品细节 >></div>
  </div>
  <div class="videoions" v-else>
    <div class="messages">
      <label class="borders"></label>
      智能硬件
      <label class="borders"></label>
    </div>
    <div style="width:100%; height: 368px;background: #fcfcfc">
      <img style="width:100%;height:100%;" :src="require('../assets/indust/' + flagone[flag].img)" alt="">
    </div>
    <div class="titles">了解更多产品细节 >></div>
  </div>
</template>

<script>
export default {
  name: "Lastpart",
  // 接受父组件的值
  props: {
    flag: Number,
    required: true
  },
  data() {
    return {
      flagone: [
        {
          img: 'baz.png'
        },
        {
          img: 'baz1.png'
        },
        {
          img: 'baz.png'
        },
        {
          img: 'baz.png'
        },
        {
          img: 'baz.png'
        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.messages {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}


.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}



.list {
  display: flex;
  position: relative;
  bottom: 40px;

}

.boximgs {
  width: 70%;
  margin-right: 50px;
}

.box {
  display: flex;
  width: 50%;
  height: 120px;
  padding: 60px;
}

.boxs {
  display: flex;
  width: 100%;

}

.boximg {
  width: 131px;
  height: 156px;
  margin-right: 50px;
}

.boxtxt {
  width: 50%;
  text-align: left;
}

.boxtxts {
  text-align: left;
}

.titles {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2681D5;
  margin: 30px 0;
}
</style>