<template>
  <!-- 方案 -->
  <div class="backimgal" v-if="flag == 0">
    <div class="videoion" style="height:600px;">
      <div class="messages">
        <label class="borders"></label>
        平台介绍
        <label class="borders"></label>
      </div>
      <div class="videoalls">
        <div v-for='(item, index) in flagone[flag].imgs' :key='index' style="width: 30%; height: 220px;">
          <img :src="require('../assets/indust/' + item.url)" style='width:326px;height:184px;' />
          <div class="tit">{{ item.tit }}</div>
          <div class="txt">{{ item.txt }}</div>
        </div>
      </div>

    </div>
  </div>
  <div class="backimgal" v-else>
    <div class="videoion" style="height:700px;">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borders"></label>
        平台介绍
        <label class="borders"></label>
        <div style="font-size:18px;margin-top:30px;font-weight: 400;">{{ flagone[flag].text }}</div>
      </div>
      <div class="videoalls">
        <div v-for='(item, index) in flagone[flag].imgs' :key='index' style="width: 100%;">
          <img :src="require('../assets/indust/' + item.url)" v-if="flag == 3" style="width:1030px;height:415px;" />
          <img :src="require('../assets/indust/' + item.url)" v-if="flag == 4" style="width:811px;height:439px;" />
          <img :src="require('../assets/indust/' + item.url)" v-if="flag == 2" style="width:838px;height:460px;" />
          <img :src="require('../assets/indust/' + item.url)" v-if="flag == 1" style="width:1020px;height:409px;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduction",
  // 接受父组件的值
  props: {
    flag: Number,
    required: true
  },
  data() {
    return {
      tablist:[
        
      ],
      flagone: [
        {
          imgs: [
            { url: "b1.png", tit: '道路交通治理平台', txt: '实时监控、车辆管理、智能预警、违章管理、布控管理、大数据监控' },
            { url: "b2.png", tit: '充电安全综合管理平台', txt: '数据分析、物视联动、充电调度、入户管控、潜在高危车主筛选' },
            { url: "b3.png", tit: '共享车辆监管平台', txt: '车辆备案、车辆监控、站点监控、网格违规行为统计监管' }],
        },
        {
          text: '数字化管理平台：电动自行车管理、消防管理、社区安防管理、停车管理、垃圾分类管理、社区大数据等',
          imgs: [
            { url: "b0.png", },
          ],
        },
        {
          text: '智慧消防管理云平台：实时监测、消防警告、大数据分析、火警联动、远程监督、GIS应用、设备管理、权限管理',
          imgs: [
            { url: "b00.png", }],

        },
        {
          text: '肉牛养殖综合管理平台：牛只入场、饲喂、离场的全流程信息化管理、牲畜管理、设备管理、牧场信息管理、告警提示',
          imgs: [
            { url: "b001.png", }],

        },
        {
          text: '固定资产管理云平台：资产盘点、资产调度、远程设置、空中升级、拆除告警等',

          imgs: [
            { url: "b002.png", }],

        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoion {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.messages {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  padding: 60px 0;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}







.videoalls {
  width: 100%;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  color: #FFFFFF;
}





.tit {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 0 30px;
  line-height: 40px;
  color: #FFFFFF;
  height: 80px;
}

.txt {
  font-size: 16px;
  padding: 0 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  text-align: left;
}


.backimgal {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/ppb.png');
  background-position: center center;
  background-size: 100% 100%;
}
</style>