<template>
  <div class="homePage">
    <!-- 轮播 -->
    <div style="background:#ccc;width:100%;min-height:560px;" :style="{ height: height + 'px' }">
      <el-carousel class="carous" :autoplay='true' indicator-position="none">
        <el-carousel-item style="width: 100%;" v-for="(item, index) in carousel" :key="index">
          <img @click='carouselClick(item)' style="width: 100%; height: 100%" :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="allvideo" style="color: white;font-size: 18px;line-height: 30px;">
      <img style="width:100%;height:100%;" src="https://advertisement.billicomm.cn/official/website/assets/index/inex.png"
        alt="">
      <div class="video" style="padding:0 30px 30px 30px;background-color: rgba(0, 153, 160, 1);">
        <div class="title" style="color: white;">我们的使命</div>
        <div class="message">
          <label class="border"></label>
          Our Mission
          <label class="border"></label>
        </div>
        <div>
          致力于成为中国领先的数字能源产品与解决方案提供商
        </div>
        <div>
          佰鹿科技始终秉承“以客户为中心，以奋斗者为本”的核心理念，不断推动行业数字化转型，持续为客户创造价值，努力践行“让生活更美好”的使命。
        </div>
      </div>
    </div>
    <div style="background-color: rgba(245, 255, 255, 1);padding-bottom: 100px;">
      <div class="video">
        <div class="title">我们的优势</div>
        <div class="message" style="color:black;">
          <label class="border" style="border-bottom: 1px solid black;"></label>
          Our Advantages
          <label class="border" style="border-bottom: 1px solid black;"></label>
        </div>
        <div class="product_top">
          <div style="width: 595px; height: 100%; background: #fcfcfc">
            <img style="width:100%;height:100%;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/one1.png" alt="">
          </div>
          <div class="righttxt">
            <div class="titles"></div>
            <div class="txts">具备智能手机产品、PAD、对讲机、以及军用融合终端的研发以及软、硬件、可靠性测试经验。</div>
          </div>
        </div>
        <div class="product_top">
          <div class="righttxt">
            <div class="titles"></div>
            <div class="txts">具备MBB类产品如５G　CPE、５G　MiFi、FWT、Router、网关、智能穿戴等产品的设计以及测试经验。</div>
          </div>
          <div style="width: 595px; height: 100%; background: #fcfcfc">
            <img style="width:100%;height:100%;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/one2.png" alt="">
          </div>
        </div>
        <div class="product_top">
          <div style="width: 595px; height: 100%; background: #fcfcfc">
            <img style="width:100%;height:100%;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/one3.png" alt="">
          </div>
          <div class="righttxt">
            <div class="titles"></div>
            <div class="txts">具备无线通讯模组类产品软、硬件设计以及测试能力，产品范围包含NB－IoT、CAT１、CAT４、CAT６等。</div>
          </div>
        </div>
        <div class="product_top">
          <div class="righttxt">
            <div class="titles"></div>
            <div class="txts">具备物联网整机设备的设计及制造经验，如儿童定位产品、工业数字化监控、智慧路灯类产品以及云支付、智能定位类产品的设计经验。
              品质控制能力和技术革新能力；能够在保质保量的情况下缩短项目周期，确保生产交期。</div>
          </div>
          <div style="width: 595px; height: 100%; background: #fcfcfc">
            <img style="width:100%;height:100%;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/one4.png" alt="">
          </div>
        </div>
        <div class="product_top">
          <div style="width: 595px; height: 100%; background: #fcfcfc">
            <img style="width:100%;height:100%;"
              src="https://advertisement.billicomm.cn/official/website/assets/index/one5.png" alt="">
          </div>
          <div class="righttxt">
            <div class="titles"></div>
            <div class="txts">具备智能手机、MBB类产品项目管理以及产品管理经验。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 热门产品 -->
    <div style="background-color:#85c9cc;">
      <div class="video" style="padding:0 30px 30px 30px;">
        <div class="title" style="color: white;">热门产品</div>
        <div class="message">
          <label class="border"></label>
          Popular Products
          <label class="border"></label>
        </div>
        <div class="sbtn">小鹿互联对讲电话</div>
      </div>
    </div>
    <div style="background-color:rgba(0, 153, 160, 1);height: 400px;">
      <div class="product_top">
        <div style="padding-top: 20px;">
          <img style="width:100%;height: 264px;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/phone.png" alt="">
        </div>
        <div class="righttxt">
          <div class="txts" style="color: white;">
            杭州佰鹿信息科技有限公司创始于2020年10月，注册资本10,000万元，法人代表杨斌先生，公司为金卡智能集团（股票代码：300349）的控股子公司。

            佰鹿科技总部位于美丽的西子湖畔—杭州，目前公司在杭州和西安分别设有运营管理中心和产品研发中心。

            佰鹿的目标是聚焦物联网行业研发、应用和生产制造，汇聚物联网行业高端人才，打造拼搏创新的团队，持续为客户提供先进的物联网产品和综合解决方案，实现与客户共赢。
          </div>
        </div>
      </div>
    </div>
    <div style="position:relative;bottom:100px;">
      <div class="video">
        <img style="width:1200px;height:100%;"
          src="https://advertisement.billicomm.cn/official/website/assets/index/photolist.png" alt="">
      </div>
    </div>
    <div>
      <div class="video">
        <div class="sbtn" style="background-color: #85c9cc;">小鹿互联APP</div>
      </div>
    </div>
    <div class="video" style="margin-top: 120px;">
      <div class="product_top">
        <div>
          <img style="width: 395px; height: 100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/app.png" alt="">
        </div>
        <div class="righttxt">
          <div class="titles"></div>
          <div class="txts">
            家长在手机端下载小鹿互联APP后，通过和小鹿互联对讲电话配合使用，可以实现儿童定位管理、轨迹查询、电子围栏及运动数据记录等多项功能。当孩子遇到紧急情况时，管理人员能够通过系统及时知晓，及时处理，避免意外发生。</div>
        </div>
      </div>
      <div class="product_top">
        <div class="righttxt">
          <div class="titles"></div>
          <div class="btns">小鹿互联网APP下载</div>
        </div>
        <div style="position:relative;right: 260px;">
          <img style="width:295px;height:100%;"
            src="https://advertisement.billicomm.cn/official/website/assets/index/code.png" alt="">
        </div>
      </div>
    </div>
    <div class="video">
      <div class="title">合作伙伴</div>
      <div class="message" style="color:black;">
        <label class="border" style="border-bottom: 1px solid black;"></label>
        Cooperative Partner
        <label class="border" style="border-bottom: 1px solid black;"></label>
      </div>
    </div>
    <div style="width:100%; height: 100%; background: rgba(0, 153, 160, 1);text-align: center;">
      <div class="right-icon">
        <img style="width: 260px;height:100%;"
          src="https://advertisement.billicomm.cn/official/website/assets/nomal/lef.png" alt="">
      </div>
      <img style="width: 1200px;height:100%;"
        src="https://advertisement.billicomm.cn/official/website/assets/index/applist.png" alt="">
    </div>
    <div style="width:100%; height: 100%; background:#85c9cc;text-align: center;padding-top: 30px;">
      <img style="width: 800px;height:100%;" src="../../assets/index/people.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "homePage",
  components: {},
  data() {
    return {
      carousel: [
        { uid: 1, name: "bannernew.png", url: "https://advertisement.billicomm.cn/official/website/assets/index/banner.png" },
        { uid: 2, name: "backs1.png", url: "https://advertisement.billicomm.cn/official/website/assets/index/banner2.png" },
        { uid: 2, name: "backs2.png", url: "https://advertisement.billicomm.cn/official/website/assets/index/banner3.png" },
        { uid: 2, name: "backs3.png", url: "https://advertisement.billicomm.cn/official/website/assets/index/banner4.png" }
      ],
      img: [
        { url: "foone.png", tit: '提供AIoT解决方案', txt: '我们拥有丰富的物联网、通信、互联网行业经验， 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
        { url: "fotow.png", tit: '提供面向需求的一站式服务', txt: '从客户需求出发，将需求变成落地的产品方案，从开发、部署到运维提供一对一、精准化的“保姆式”服务。 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
        { url: "fothr.png", tit: '输出核心能力：业务平台、 研发、供应链、生产制造', txt: '更有效率的全价值链协同管理，以成熟的技术能力，向客户提供AIoT软硬一体化解决方案，帮助客户及终端用户降本增效。 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
      ],
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientWidth / 4.47
    };
  },
  mounted() {
    window.onresize = () => {
      this.width = document.documentElement.clientWidth,
        this.height = document.documentElement.clientWidth / 4.47
    }
  },
  methods: {
    carouselClick(val) {
      if (val.uid == 2) {
        // this.$router.push('/DetailZ')
      }
    }
  }
};
</script>
<style lang="scss" >
.carous {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  background: #fcfcfc;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.video {
  width: 1200px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.el-carousel__container {
  height: 100%;
  min-width: 1200px;
  justify-content: space-between;
  align-items: center;
}

.title {
  height: 50px;
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #121212;
  padding: 60px 0 10px 0;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: white;
  margin-bottom: 60px;
}

.border {
  border-bottom: 1px solid white;
  width: 260px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.tit {
  margin-top: 40px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
}

.tits {
  margin-top: 5px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 160px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.videos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #FFFFFF;
}

.videos .txt {
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid #F8FAFB;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.backimg {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/img/back.png');
  background-position: center center;
  width: 100%;
  height: 780px;
}

.product_top {
  display: flex;
  height: 283px;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 46px;
  padding: 0 10px;
}

.righttxt {
  width: 595px;
  height: 100%;
  text-align: left;
  padding: 30px 60px;
  box-sizing: border-box;
}

.righttxt .titles {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #167BCE;
  margin-bottom: 30px;
  width: 98px;
  padding-bottom: 20px;

}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6B6B6B;
  line-height: 30px
}

.allvideo {
  width: 100%;
  background-color: #85c9cc;
}

.sbtn {
  width: 1200px;
  height: 140px;
  background: #85c9cc;
  border-radius: 80px;
  font-size: 70px;
  line-height: 140px;
  color: white;
}

.right-icon {
  position: absolute;
  left: 0;
}

.allvideo>.video {
  position: relative;
  bottom: 140px;
}

.btns {
  width: 337px;
  height: 64px;
  background: #0099A0;
  border-radius: 32px;
  line-height: 64px;
  color: white;
  font-size: 28px;
  text-align: center;
  position: relative;
  left: 100px;
}
</style>
