<template>
  <!-- 方案场景 -->
  <div class="videoions" v-if="flag == 0">
    <div class="message">
      <label class="borders"></label>
      方案场景
      <label class="borders"></label>
    </div>
    <div v-if="dex == 2">
      <div class="product">
        <div>
          <img style="width:285px;height:160px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c31.png" alt="">
          <div class="titall">政府机关车队管理</div>
        </div>
        <div>
          <img style="width:285px;height:160px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c32.png" alt="">
          <div class="titall">智慧园区车辆管理</div>
        </div>
        <div>
          <img style="width:285px;height:160px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c33.png" alt="">
          <div class="titall">企事业单位车队管理</div>
        </div>
        <div>
          <img style="width:285px;height:160px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c34.png" alt="">
          <div class="titall">旅游客车管理</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="product" style="margin-top:50px;">
        <div style="width:50%; height: 338px;background: #fcfcfc">
          <img style="width:100%;height:100%;" :src="require('../assets/product/' + tabarr[dex].img1)" alt="">
        </div>
        <div class="righttit">
          <div class="titlea">{{ tabarr[dex].title1 }}</div>
          <div class="txts">
            {{ tabarr[dex].txt3 }}
          </div>
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div class="righttit">
          <div class="titlea">{{ tabarr[dex].title2 }}</div>
          <div class="txts">
            {{ tabarr[dex].txt3 }}
          </div>
        </div>
        <div style="width:50%; height: 338px;background: #fcfcfc">
          <img style="width:100%;height:100%;" :src="require('../assets/product/' + tabarr[dex].img2)" alt="">
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div style="width:50%; height: 338px;background: #fcfcfc">
          <img style="width:100%;height:100%;" :src="require('../assets/product/' + tabarr[dex].img3)" alt="">
        </div>
        <div class="righttit">
          <div class="titlea">{{ tabarr[dex].title3 }}</div>
          <div class="txts">
            {{ tabarr[dex].txt3 }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="videoions" v-else-if="flag == 1">
    <div class="message">
      <label class="borders"></label>
      方案场景
      <label class="borders"></label>
    </div>
    <div class="product">
      <div style="margin:10px;">
        <img style="width:100%;height:324px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c11.png" alt="">
        <div class="titalls">社区垃圾分类管理</div>
      </div>
      <div style="margin:10px;">
        <img style="width:100%;height:324px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c12.png" alt="">
        <div class="titalls">智慧社区打造</div>
      </div>
    </div>
    <div class="product">
      <div style="margin:10px;">
        <img style="width:100%;height:324px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c13.png" alt="">
        <div class="titalls">智慧物业管理</div>
      </div>
      <div style="margin:10px;">
        <img style="width:100%;height:324px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c14.png" alt="">
        <div class="titalls">居民区安防管理</div>
      </div>
    </div>
  </div>
  <div class="videoions" v-else-if="flag == 4">
    <div class="message">
      <label class="borders"></label>
      方案场景
      <label class="borders"></label>
    </div>
    <div class="product">
      <div>
        <img style="width:388px;height:238px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c21.png" alt="">
        <div class="titall">资产密集型企业资产管理如电力、石化、矿业、航运等行业</div>
      </div>
      <div>
        <img style="width:388px;height:238px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c22.png" alt="">
        <div class="titall">资产高价型企业资产管理如银行、珠宝、贵金属、汽车等行业</div>
      </div>
      <div>
        <img style="width:388px;height:238px;" src="https://advertisement.billicomm.cn/official/website/assets/product/c23.png" alt="">
        <div class="titall">航运、物流货物定位追踪管理 </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringify } from 'querystring';

export default {
  name: "Lastpart",
  // 接受父组件的值
  props: {
    flag: Number,
    dex: Number,
    required: true
  },
  data() {
    return {
      tabarr: [
        {
          img1: 'c1.png', title1: '道路交通治理方案', txt1: '通过物联网进行实时位置追踪，联动电动车管理平台、中心视频监控、电子警察，形成治安“天罗地网”，完成电动车的全程监管，从各个角度协助政府实现电动车城市综合管理体系的完善',
          img2: 'c2.png', title2: '充电安全管理方案', txt2: '通过大数据分析，提供多技术手段分类分级管控重点人群；对潜在高危车主进行筛选，通过“技防+人防”手段重点管控，维护安全用电、合理停放的社区环境。',
          img3: 'c3.png', title3: '共享电单车管理方案', txt3: '实时展示辖区内各车企运营情况，包括车辆备案、投放情况，订单情况，违规情况，网格状态，车辆实时分布，站点实时停车状况，违规车辆分布等。',
        },
        {
          img1: 'c4.png', title1: '城市路边停车管理', txt1: '通过物联网进行实时位置追踪，联动电动车管理平台、中心视频监控、电子警察，形成治安“天罗地网”，完成电动车的全程监管，从各个角度协助政府实现电动车城市综合管理体系的完善',
          img2: 'c5.png', title2: '景区停车管理', txt2: '节假日旅游高峰期，景区停车数量剧增，通过佰鹿科技智慧停车解决方案能有效监控车位使用情况，让车位利用率达到最高，提升运营效率。',
          img3: 'c6.png', title3: '社区停车管理', txt3: '小区私家车保有量剧增，通过佰鹿科技智慧停车解决方案为车主提供方便的寻找车位服务，节约车主时间，让小区管理更和谐更智能。',
        },


      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  padding: 60px 0;
}

.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}




.righttxts {
  text-align: left;
  padding: 50px;
  box-sizing: border-box;
  width: 40%;
  color: #FFFFFF;
  background: #175085;
}


.txts {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 36px
}



.product {
  display: flex;
  width: 1200px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
}

.righttit {
  text-align: left;
  padding: 50px;
  box-sizing: border-box;
  width: 50%;
  color: #151F2B;
}

.txts {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 36px
}

.titalls {
  text-align: center;
  padding: 30px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #121212;
}

.titlea {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.titall {
  text-align: center;
  padding: 20px 30px 60px 30px;
  font-size: 18px;
  height: 60px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #121212;
  line-height: 30px;
}
</style>