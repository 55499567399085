<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.billicomm.cn/official/website/assets/bj.png" style="width: 100%; height: 100%" />
    </div>
    <div class="centertop">
      <div v-for="(item, index) in dataList" :key="index" :class="selectedIndex == index ? 'active' : 'boxsty'"
        @click.stop="selectBox(item, index)">
        {{ item.value }}
      </div>
    </div>
    <div class="videoall">
      <div class="product">
        <div class="righttxtall">
          <div>{{ flagone[selectedIndex].title }}</div>
          <div class="txts">{{ flagone[selectedIndex].txt }}</div>
        </div>
        <div style="text-align:center; background: #fcfcfc">
          <img style="width:314px;height: 174px;margin-right:10px"
            :src="require('../../assets/paltform/' + flagone[selectedIndex].img)" alt="">
          <img style="width:314px;height: 174px;" :src="require('../../assets/paltform/' + flagone[selectedIndex].imgs)"
            alt="">
        </div>
      </div>
      <div class="product" style=" position: relative;top: 65px;">
        <div style=" text-align:center; background: #fcfcfc;margin-right:30px;">
          <img style="width:500px;height: 387px;margin-right:10px"
            :src="flagone[selectedIndex].back" alt="">
        </div>
        <div class="righttxtall">
          <div class="txts">{{ flagone[selectedIndex].txts }}</div>
        </div>
      </div>
    </div>
    <img src="https://advertisement.billicomm.cn/official/website/assets/product/tiao.png"
      style="width: 100%; height: 170px;position: relative;top: 20px;" />
    <div class="videoall" v-if="selectedIndex == 0" style="margin-top:20px;margin-bottom:20px;">
      <div style="color: #000529;font-size: 26px;text-align:center;padding:60px 0;">
        基于防盗设备智能感知、多维度交叉判断、事件逻辑，构建骑行违规证据链
      </div>
      <div class="videosduct">
        <div v-for='(item, index) in imgslist' :key='index' style="width:33%;">
          <img :src="require('../../assets/paltform/' + item.url)" style=" width:130px;height: 130px;" />
          <div style="font-size: 18px;margin:20px 0;font-weight: 500;color: #747575;padding:0 40px;">{{ item.txt }}
          </div>
        </div>
      </div>
    </div>
    <Lastpart :flag="selectedIndex"></Lastpart>
  </div>
</template>

<script>
import Lastpart from "../../components/Lastpart";
export default {
  name: "product",
  components: {
    Lastpart
  },
  data() {
    return {
      selectedIndex: 0,
      dataList: [
        { key: '1', value: "非机动车交通违章管理平台" },
        { key: '2', value: "电动自行车充电安全管理平台" },
        { key: '3', value: "共享电单车管理平台" },
        { key: '4', value: "路侧停车管理平台" }
      ],
      imgslist: [
        { url: "o1.png", txt: '红灯纠章、超速判定准确率高达100%' },
        { url: "o2.png", txt: '电动自行车纠章效率提升40%' },
        { url: "o3.png", txt: '被盗车辆最快30分钟找回' },
        { url: "o4.png", txt: '车辆逆行纠章准确率高达85%' },
        { url: "o5.png", txt: '盗窃破案率提升90%以上' }],
      height: document.documentElement.clientWidth / 4.47,
      imgone: [
        { url: "11.png", tip: '车辆高温告警', txp: '实时违章信息滚动显示，实时显示违章图像信息' },
        { url: "22.png", tip: '入户充电告警', txp: '设置监控路段以及监控路口信息、超速围栏规则、逆行围栏规则等' },
        { url: "33.png", tip: '违规充电告警', txp: '查询车辆位置信息、历史轨迹、盗抢车辆处理、警务信息分发、处理进度跟踪' }],
      flagone: [
        { title: '解决电动自行车交通违规难题', txts: '通过物联网进行实时位置追踪，联动电动车管理平台、中心视频监控、电子警察，形成治安“天罗地网”，完成电动车的全程监管，从各个角度协助政府实现电动车城市综合管理体系的完善。', txt: '逆行、超速、闯红灯等违规行为——缺乏建设统一的数据监管平台', img: '1.png', imgs: '2.png', back: 'https://advertisement.billicomm.cn/official/website/assets/paltform/3.png' },
        { title: '解决电动自行车充电安全难题', txts: '采用高灵敏度的温度传感器和温度监测处理单元，实现对环境温度的监测，电动车温度过高时立即发出告警，对电动车入户充电、起火等起到预警作用。', txt: '入户充电、充电起火等危险行为——缺乏建设统一的数据监管平台', img: '11.png', imgs: '12.png', back: 'https://advertisement.billicomm.cn/official/website/assets/paltform/23.png' },
        { title: '解决共享电单车统一管理难题', txts: '实时展示辖区内各车企运营情况，包括车辆备案、投放情况、订单情况、违规情况、网格状态、车辆实时分布、站点实时停车状况、违规车辆分布等。', txt: '乱停放、跨区域行驶——缺乏建设统一的数据监管平台', img: '21.png', imgs: '22.png', back: 'https://advertisement.billicomm.cn/official/website/assets/paltform/13.png' },
        { title: '解决路侧停车难、停车场管理难题', txts: '综合路边停车难、停车场管理难等痛点，构建统一、完善的停车管理云平台，对城市车辆管理提供信息化、智能化的全方位优化管理与控制，降低人工成本，提升管理者的工作效率及车位周转率。', txt: '违章停车、收费混乱、停车位难找——缺乏建设统一的数据监管平台', img: '01.png', imgs: '02.png', back: 'https://advertisement.billicomm.cn/official/website/assets/paltform/03.png' },
      ],

    };
  },
  methods: {
    selectBox(item, index) {
      this.selectedIndex = index;
    },

  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}


.videoall {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}


.videosduct {
  width: 100%;
  display: flex;
}


.product {
  display: flex;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.righttxtall {
  height: 100%;
  text-align: left;
  width: 45%;
  padding-top: 20px;
  box-sizing: border-box;
}


.righttxtall .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #747575;
  padding: 40px 0;
  line-height: 30px
}


.centertop {
  text-align: center;
  border-bottom: 1px solid #E6E6E6;
  font-size: 24px;
  display: flex;
  width: 1200px;
  margin: 60px auto;
  justify-content: space-between;
  align-items: center;
}

.centertop .active {
  color: #2681D5;
  border-bottom: 2px solid #006FD4;
}

.centertop div:hover {
  color: #2681D5;
}

.centertop div {

  height: 60px;
  line-height: 60px;
  display: inline-block;
  color: #000000;
}

.left {
  width: 30%;
  margin: 1px;
  height: 43px;
  font-size: 18px;
  background-color: #167BCE;
  text-align: left;
  padding-left: 120px;
  box-sizing: border-box;
  line-height: 44px;
  color: #FFFFFF;
}

.right {
  width: 69%;
  height: 43px;
  font-size: 18px;
  background-color: #EEEEEE;
  text-align: left;
  padding-left: 100px;
  box-sizing: border-box;
  line-height: 44px;
  color: #000000;
}
</style>