<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.billicomm.cn/official/website/assets/index/banner2.png"
        style="width: 100%; height: 100%" />
    </div>
    <div class="centertop">
    </div>
    <div class="video">
      <div class="messageother">
        <label class="border"></label>
        {{ flagone[selectedIndex].title }}
        <label class="border"></label>
      </div>
      <div style="margin: 20px 0;">Product Introduction</div>
      <div style="width:100%;" v-if="selectedIndex == 0">
        <div style="text-align: center;line-height: 40px;font-size: 18px;">
          <div class="txts">{{ flagone[selectedIndex].txt }}</div>
        </div>
        <div>
          <img src="https://advertisement.billicomm.cn/official/website/assets/nomal/phone.png"
            style='width:359px;margin-top:50px' />
        </div>
      </div>
      <div class="product_top" v-else>
        <div class="righttxt" style=" padding: 30px 0;">
          <div class="txts">{{ flagone[selectedIndex].txt }}</div>
        </div>
        <div style="width: 50%; text-align:center; background: #fcfcfc">
          <img :src="require('../../assets/product/' + flagone[selectedIndex].img)" style='width:348px;height:180px;' />
        </div>
      </div>
    </div>
    <div style="background-color: rgba(143, 202, 205, 1);">
      <div class="video">
        <div class="messageother" style="color:white;padding-top: 50px;">
          <label class="border" style="border-bottom: 2px solid white;"></label>
          功能展示
          <label class="border" style="border-bottom: 2px solid white;"></label>
        </div>
        <div style="margin: 20px 0;color:white;">Function Demonstration</div>
      </div>
    </div>
    <div style="background-image: url('https://advertisement.billicomm.cn/official/website/assets/nomal/bg.png');
              background-position: center center;padding-top: 50px ;
              background-size: 100% 100%;">
      <div class="video">
        <div class="videos">
          <div v-for='(item, index) in flagthr[0].imgslist' :key='index' class="bottomimgs">
            <div class="img">
              <img style="width:54px;height:64px;" :src="require('../../assets/nomal/' + item.url)" alt="">
              <div style="font-size: 20px;margin:20px 0;font-weight: 600;color: black;">{{ item.txt }}</div>
              <div style="font-size: 16px;margin:20px 0;font-weight: 500;color: #747575;text-align:left;padding:0 20px">
                {{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="videos">
          <div v-for='(item, index) in flagthr[1].imgslist' :key='index' class="bottomimgs">
            <div class="img">
              <img style="width:54px;height:64px;" :src="require('../../assets/nomal/' + item.url)" alt="">
              <div style="font-size: 20px;margin:20px 0;font-weight: 600;color: black">{{ item.txt }}</div>
              <div style="font-size: 16px;margin:20px 0;font-weight: 500;color: #747575;text-align:left;padding:0 20px">
                {{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <div class="messageother" style="padding-top: 50px;">
        <label class="border"></label>
        产品优势
        <label class="border"></label>
      </div>
      <div style="margin: 20px 0;">Product Advantages</div>
      <div class="videos" v-for='(item, index) in flagfour[selectedIndex].textlist' :key='index'>
        <span class="left">
          <img :src="require('../../assets/nomal/' + item.url)"
            style='width:30px;margin-right: 10px;position: relative;top: 10px;' />
          {{ item.left }}
        </span>
        <span class="right">
          <img :src="require('../../assets/nomal/' + item.urls)"
            style='width:30px;margin-right: 10px; position: relative;top: 10px;' />
          {{ item.txt }}</span>
      </div>
    </div>
    <div style="position: relative;">
      <div class="video">
        <img src="https://advertisement.billicomm.cn/official/website/assets/nomal/lef.png"
          style='position: absolute;left: 0;bottom:120px;width: 300px;' />
          <img src="https://advertisement.billicomm.cn/official/website/assets/nomal/rig.png"
          style='position: absolute;right: 0;bottom:360px;width: 300px;' />
        <div class="messageother" style="margin-top: 30px;">
          <label class="border"></label>
          售后保障
          <label class="border"></label>
        </div>
        <div style="margin-top: 20px ;">After Sales</div>
      </div>

      <div style="background-color: rgba(0, 153, 160, 1);">
        <div class="video" style="padding: 30px 0;font-size: 22px;font-weight: 400;
      color: #FFFFFF;line-height:40px;">
          <div>小鹿互联拥有完善的售后服务体系</div>
          <div>产品质保1年</div>
          <div>质保期内出现任何质量问题均会第一时间为您处理！</div>
          <div class="btn">全国售后热线：18991686430</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      dataList: [
        { key: '1', value: "定位终端" },
        { key: '2', value: "环境感知终端" },
        { key: '3', value: "无线感知终端" },
        { key: '4', value: "生理参数感知终端" },
        { key: '5', value: "安全信息感知终端" },
        { key: '6', value: "通话定位感知终端" },
      ],
      imgone: [
        { url: "1q.png", tip: '多重精准定位', txp: 'GPS、WIFI、基站等多种定位方式，室内外无死角全覆盖。' },
        { url: "2q.png", tip: 'SOS一键呼救', txp: '当在外遇到危险时，可通过 SOS一键报警，家人第一时间了解情况。' },
        { url: "3q.png", tip: '围栏布控', txp: '划定电子围栏安全区域，进出围栏都有提醒。' }],
      imgtwo: [
        { url: "4q.png", tip: '轨迹查看', txp: '随时查看动态轨迹，历史轨迹保留30天。' },
        { url: "5q.png", tip: '持久续航', txp: '采用高密度聚合物锂电池，安全环保，超长待机， 避免频繁充电，省电模式下待机长达60天。' }],
      textlist: [
        { left: "定位方式", txt: 'GPS、 WiFi 、基站' },
        { left: "射频频段", txt: 'Band5、 Band8' },
        { left: "尺寸", txt: '43.6mm*43.6mm*16mm' },
        { left: "重量", txt: '约50g' },
        { left: "续航能力", txt: '2天（追踪模式）100天（纯待机）' },
        { left: "使用环境温度", txt: '-30℃至70℃' },
        { left: "使用环境湿度", txt: '15%-95%相对湿度' },
        { left: "防水能力", txt: 'IP54' },],
      imgslist: [
        { url: "y1.png", txt: '老人安全守护' },
        { url: "y2.png", txt: '孩子防走丢' },
        { url: "y3.png", txt: '爱车放丢失' },
        { url: "y4.png", txt: '贵重物品放丢失' },
        { url: "y5.png", txt: '安防与绩效考核管理' }],
      height: document.documentElement.clientWidth / 4.47,
      imgs: [
        { url: "litsone.png" },
        { url: "litstwo.png" },
        { url: "litsthr.png" }],
      flagone: [
        { title: '产品简介', backimg: 'backimg1', txt: '小鹿互联对讲电话是杭州佰鹿信息科技有限公司自主研发生产的新概念儿童健康防干扰电话，本产品以“简约纯净”为理念，采用无屏幕设计，专注核心功能，能够保护儿童视力，防止沉迷，更适合学龄期小朋友使用。', img: 'phone0.png', imgs: 'phones.png' },
      ],
      flagtwo: [
        {
          imgone: [
            { url: "01.png", tip: '电池管理', txp: '通过加装电源控制和自检模块，实现电量显示，智能充放电监测等功能，让充放电更加安全，有效延长电池使用寿命。' },
            { url: "02.png", tip: '实时定位', txp: '采用北斗+GPS+基站定位，自动切换，覆盖范围广，让爱车永远在线。' },
            { url: "03.png", tip: '异动警报', txp: '不间断的多重定位，姿态传感器每分钟收集数百次的车辆数据，对比数据变化，异动信息即时警报。' }],
          imgtwo: [
            { url: "04.png", tip: '电子围栏', txp: '设定指定区域后，车辆进入或者离开围栏是都会记录并且触发警报，告警信息自动发送到监控中心及车主手机。' },
            { url: "05.png", tip: '高温警报', txp: '设备可实时感知环境温度，温度达到阈值时提醒车主及时处理，对电动车着火起到预警功效。' },
            { url: "06.png", tip: '轨迹查询', txp: '车主可通过平台查询车辆的历史行驶轨迹，轨迹保留30天。' }],
        },
        {
          imgone: [
            { url: "11s.png", tip: '运行稳定', txp: 'NB-IoT通信，覆盖范围广，传输稳定。' },
            { url: "22s.png", tip: '涵盖全面', txp: '卡片类型多样化、可检测多种环境数据。' },
            { url: "33s.png", tip: '安装便利', txp: '重量轻、体积小、可快速部署。' }],
          imgtwo: [
            { url: "44.png", tip: '参数多样', txp: '温度/湿度/震动/光线强弱/倾斜角/PH值/化学物质/甲醛/PM2.5等均可监控。' },
            { url: "55.png", tip: '持久续航', txp: '电池容量大、低功耗、续航更持久。' }],
        },
        {
          imgone: [
            { url: "31t.png", tip: '检测精准', txp: '采用NB-IoT物联网的信息融合技术，感应灵敏度极高≥99.99%，能进行复杂停车环境的高精度检测。' },
            { url: "32t.png", tip: '品质保障', txp: '外壳采用工程塑钢PC材质，基于工程学及力学的外观设计，能承受极大的外力冲击。' },
            { url: "33t.png", tip: '管理者(云平台)', txp: '车辆流量分析分流引导服务停车收费统计违停逃费管理' }],
          imgtwo: [
            { url: "34t.png", tip: '运营者(手持终端)', txp: '实时停车地图车位资源提醒收费跑漏统计运营收益统计员工管理考核' },
            { url: "35t.png", tip: '车主(公众号/小程序)', txp: '实时空位查询停车分流引导线上支付费用补缴违停费用车牌绑定与寻车' }],
        },
        {
          imgone: [
            { url: "1w.png", tip: '温度监控', txp: '内置温度传感器，实时监控动物的健康状况，不再错过牛生病的救治的黄金时间，避免造成养殖户惨痛的损失。' },
            { url: "2w.png", tip: '精确定位', txp: '音质清晰，随时接听,多重定位功能，动物跑到了哪里，一看就知道，放牧、散养再也没有操不完的心。' },
            { url: "3w.png", tip: '电子围栏', txp: '当动物离开设置好的安全区域时，定位器自动发送报警信息，有效防止走失被盗现象的发生。' }],
          imgtwo: [
            { url: "4w.png", tip: '超长待机', txp: '大容量的聚合物锂电池，省去充电烦恼，正常模式待机可使用18个月。' },
            { url: "5w.png", tip: '防拆设计', txp: '耳钉式造型设计，搭配防盗扣牢牢固定，还有防拆警报陪伴养殖的整个生长周期。' },
            { url: "6w.png", tip: '外观轻巧', txp: '一个火柴盒的大小，比一颗鸡蛋的还轻的重量。' },
            { url: "7w.png", tip: '抗冻防寒', txp: '电池采用耐寒耐高低温材料，恶劣环境中也可以正常工作。' }],
        },
        {
          imgone: [
            { url: "1d.png", tip: '灵敏检测', txp: '全方位敏锐捕捉烟雾，烟雾达到报警值立即报警鸣叫及指示灯闪烁提醒。' },
            { url: "2d.png", tip: '远程防护', txp: '烟雾报警器通过与云平台连接，实现消防状态实时可视化管控，当设备监测到异常时，系统实时发出多重报警。' },
            { url: "3d.png", tip: '安装简易', txp: '免布线、免接电、免网关，采用双面胶、螺丝钉两种安装方式，安装更方便。' }],
          imgtwo: [
            { url: "4d.png", tip: '支持集中管理', txp: 'APP支持添加多个烟雾报警器，实现统一管理，报警信息可通过APP、电话、短信、微信公众号多重远程报警。' },
            { url: "5d.png", tip: '超低功耗', txp: '采用低功耗设计，正常使用可达3年，免去频繁更换电池的烦恼。当电压低或者出现故障时，APP自动发出提示。' }],
        },
        {
          imgone: [
            { url: "1.png", tip: '多重定位', txp: '支持GPS、GLONASS、北斗、基站、WiFi、AGPS、Galileo、加速度传感器八重高精度定位，定位范围更广更精准。' },
            { url: "2.png", tip: '双向通话', txp: '音质清晰，随时接听,不管孩子在图书馆还是公园，关爱永不失联' },
            { url: "3.png", tip: '紧急呼救', txp: '当孩子突发紧急情况，家长立刻知晓，避免意外发生。' }],
          imgtwo: [
            { url: "4.png", tip: '家庭群聊', txp: '温家庭微聊，轻松沟通亲子互动，支持多人对讲，让距离不再是问题。' },
            { url: "5.png", tip: '智能闹钟', txp: '自主设定闹钟，语音提醒孩子规律作息' },
            { url: "6.png", tip: '历史轨迹', txp: '180天云端存储记录，查询历史纪录' },
            { url: "7.png", tip: '勿扰模式', txp: '灵活设置上课禁用时段，不影响课堂秩序' }],
        }
      ],
      flagthr: [
        {
          imgslist: [
            { url: "one.png", txt: '多重定位', text: '支持GPS、GLONASS、北斗、基站、WiFi、AGPS、Galileo、 加速度传感器八重高精度定位，定位范围更广更精准。' },
            { url: "two.png", txt: '双向通话', text: '音质清晰，随时接听，不管孩子在图书馆还是公园，关爱永不失联。' },
            { url: "thr.png", txt: 'SOS报警', text: '当孩子突发紧急情况，家长立即知晓，避免意外发生。' },
            { url: "four.png", txt: '趣味对讲', text: '乐享新社交，高清对讲功能，让朋友之间的距离不再是问题。' }],
        },
        {
          imgslist: [
            { url: "five.png", txt: '家庭群聊', text: '沟通不止打电话,家庭群聊助力亲子互动，有助于从小培养孩子的亲情意识,让距离不再是问题。' },
            { url: "six.png", txt: '智能闹钟', text: '自主设定闹钟，语音提醒孩子规律休息，培养孩子科学作息习惯。' },
            { url: "seven.png", txt: '电子围栏', text: '安全守护，孩子的贴身保镖，设置家、学校等常用地址后，孩子离开家或学校都会收到提醒。' },
            { url: "eig.png", txt: '超长续航', text: '超长续航能力，充一次电待机7天采用前沿电池技术，更耐用、更安全。智能省电 ，守护更持久，过压过流保护 安全升级。' }],
        },
      ],
      flagfour: [
        {
          textlist: [
            { left: "音质清晰辐射低，具备国家3C认证", txt: '多人监护，可添加多个监护人对设备进行管理和查看', url: "1.png", urls: "2.png", },
            { left: "RTOS全封闭系统，不支持第三方APP安装，安全保护用户信息", txt: '八重定位，更精准更安全', url: "3.png", urls: "4.png", },
            { left: "无屏幕设计，保护孩子视力，不会泄露用户肖像", txt: '智能省电，一周以上超长待机', url: "5.png", urls: "6.png", },
            { left: "无游戏，无视频，无陌生交友，不影响学习", txt: '团队深耕物联网领域，技术成熟稳定', url: "7.png", urls: "8.png", },
            { left: "产品均采用环保配件，保证孩子健康", txt: '售后体系完整', url: "9.png", urls: "10.png", }]
        },
      ]
    };
  },
  methods: {
    selectBox(item, index) {
      this.selectedIndex = index;
    },

  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}


.backimg {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback0.png');
}

.backimg1 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback1.png');
}

.backimg2 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback2.png');
}

.backimg3 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback3.png');
}

.backimg4 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback4.png');
}

.backimg5 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback5.png');
}

.backimg6 {
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/product/wback.png');
}

.backimg,
.backimg1,
.backimg2,
.backimg3,
.backimg4,
.backimg5,
.backimg6 {
  background-position: center center;
  background-size: 100% 100%;
  padding: 120px 0 50px 0;
}

.video {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.videoter {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 0 60px 30px 60px;
}

.titother {
  color: #FFFFFF;
  font-size: 26px;
  text-align: left;
  position: relative;
  bottom: 80px;
}

.lastpart {
  text-align: center;
  height: 280px;
  width: 100%;
  background-color: #E0F0FD;
  line-height: 30px;
  position: relative;
  top: 20px;
}

.lastpart button {
  width: 313px;
  height: 56px;
  border-radius: 30px;
  background-color: #167BCE;
  color: #FFFFFF;
  border: none;
  font-size: 24px;
  margin: 50px 0;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.title {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 20px 0;
}

.messageother {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}

.messageother>.border {
  border-bottom: 2px solid black;
  width: 120px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.messages {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  margin: 60px 0;
}

.border {
  border-bottom: 1px solid #C9C9C9;
  width: 500px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.tip {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 46px;
}

.txp {
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 470px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.videos {
  width: 100%;
  display: flex;
}

.product_top {
  display: flex;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.righttxt {
  width: 50%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
}

.rigbottom {

  width: 600px;
  height: 100%;
  text-align: left;
  padding: 0 60px;
  box-sizing: border-box;
}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #747575;
  line-height: 30px
}


.bottomimg {
  width: 388px;
  height: 496px;
}

.bottomimgs {
  width: 30%;
  margin-right: 2%;
  height: 275px;
  margin-bottom: 20px;
  position: relative;
  background-image: url('https://advertisement.billicomm.cn/official/website/assets/nomal/bf.png');
  background-position: center center;
  background-size: 100% 100%;
}

.bottomimgs>.img {
  height: 200px;
  width: 268px;
  position: absolute;
  top: 20px;
  right: 14px;
}

.centertop {
  text-align: center;
  margin: 60px 0;
  font-size: 24px;
}

.centertop label {
  width: 200px;
  height: 50px;
  line-height: 50px;
  margin: 0 1px;
  border-radius: 5px;
  background-color: #7799BB;
  display: inline-block;
  color: #FFFFFF;
}

.centertop .active {
  background-color: #0163B4;
}

.centertop label:hover {
  background-color: #0163B4;
}

.left {
  width: 50%;
  margin: 1px;
  height: 80px;
  font-size: 18px;
  background-color: rgba(133, 201, 204, 1);
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 80px;
  color: #000000;
}

.btn {
  width: 1200px;
  height: 140px;
  background: #85c9cc;
  //   background: #FFFFFF;
  // opacity: 0.3;
  border-radius: 80px;
  font-size: 70px;
  line-height: 140px;
  margin: 20px;
  color: white;
}

.right {
  width: 49%;
  height: 80px;
  font-size: 18px;
  background-color: rgba(168, 231, 231, 1);
  text-align: left;
  padding-left: 30px;
  box-sizing: border-box;
  line-height: 80px;
  margin-top: 1px;
  color: #000000;
}
</style>